<template>
    <div>
        <div class="container" style="margin-bottom: 94px;">
            <div v-if="loading">
            </div>
            <div v-else>
                <div v-if="task">
                    <h4 class="text-center mt-3">Töö nr {{ task.id }}</h4>
                    <ProgressSteps :currentStage="task.stage_id" :totalStages="3" :selectedStage="selectedStage"
                        :finished="task.finished" @stage-selected="selectedStage = $event" />
                    <TaskComponent :task="task" :editable="true" @update-estimated-date="handleUpdateEstimatedDate" />

                    <div class="accordion shadow mt-3" id="accordionNotes" v-if="[0, 1].includes(selectedStage)"
                        v-on="{ 'shown.bs.collapse': this.scrollToElement }">
                        <div class="accordion-item border-0">
                            <h2 class="accordion-header">
                                <button class="accordion-button fs-6 fw-bolder" type="button"
                                    :class="{ 'text-body-tertiary': !task.notes }" data-bs-toggle="collapse"
                                    data-bs-target="#collapseNotes" aria-expanded="false" aria-controls="collapseNotes">
                                    Töö kirjeldus
                                </button>
                            </h2>
                            <div id="collapseNotes" class="accordion-collapse collapse show"
                                data-bs-parent="#accordionNotes">
                                <div class="accordion-body">
                                    <div v-if="task.notes" class="small text-multiline text-break">
                                        {{ task.notes }}
                                    </div>
                                    <div v-else class="fst-italic small">
                                        Kirjeldus puudub
                                    </div>
                                    <ul v-if="task.files.length > 0" class="list-unstyled">
                                        <li class="my-2 list-group-item d-flex justify-content-between align-items-start" v-for="file in task.files" :key="file.id">
                                            <div v-if="file.content_type == 1" class="btn btn-light w-100 text-start text-truncate small" @click="requestFile(file)"><i class="fa-solid fa-paperclip me-2"></i>{{ file.filename }}</div>
                                            <a v-else target="_blank" :href="file.url" role="button" class="btn btn-light w-100 text-start text-truncate small"><i class="fa-solid fa-at me-2"></i>{{ file.url }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion shadow mt-3" id="accordionMemo4" v-if="[0, 1].includes(selectedStage)"
                        v-on="{ 'shown.bs.collapse': this.scrollToElement }">
                        <div class="accordion-item border-0">
                            <h2 class="accordion-header">
                                <button class="accordion-button fs-6 fw-bolder" type="button"
                                    :class="{ 'text-body-tertiary': !task.project_memo4 }" data-bs-toggle="collapse"
                                    data-bs-target="#collapseMemo4" aria-expanded="false" aria-controls="collapseMemo4">
                                    Objekti tehniline info
                                </button>
                            </h2>
                            <div id="collapseMemo4" class="accordion-collapse collapse show"
                                data-bs-parent="#accordionMemo4">
                                <div class="accordion-body">
                                    <div v-if="task.project_memo4" class="small text-multiline text-break">
                                        {{ task.project_memo4 }}
                                    </div>
                                    <div v-else class="fst-italic small">
                                        Kirjeldus puudub
                                    </div>
                                    <ul v-if="task.project_files.length > 0" class="list-unstyled">
                                        <li class="my-2 list-group-item d-flex justify-content-between align-items-start" v-for="file in task.project_files" :key="file.id">
                                            <div class="btn btn-light w-100 text-start text-truncate" @click="requestProjectFile(file)"><i class="fa-solid fa-paperclip me-2"></i><small>{{ file.filename }}</small></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion shadow mt-3" id="accordionReminders"
                        v-if="task.object_id && [0, 1, 2].includes(selectedStage)"
                        v-on="{ 'shown.bs.collapse': this.scrollToElement }">
                        <div class="accordion-item border-0">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed fs-6 fw-bolder" type="button"
                                    :class="{ 'text-body-tertiary': reminders.length == 0 }" data-bs-toggle="collapse"
                                    data-bs-target="#collapseReminder" aria-expanded="false"
                                    aria-controls="collapseReminder">
                                    Seadmega seotud meeldetuletused
                                </button>
                            </h2>
                            <div id="collapseReminder" class="accordion-collapse collapse"
                                data-bs-parent="#accordionReminders">
                                <div class="accordion-body">
                                    <ul v-if="reminders.length > 0" class="list-unstyled">
                                        <li v-for="(reminder, index) in reminders" :key="reminder.id" class="my-2">
                                            <input class="form-check-input me-1" type="checkbox" :value="reminder.id"
                                                :id="`checkbox-${index}`"
                                                :disabled="reminder.is_permanent || task.finished"
                                                v-model="reminder.checked"
                                                @change="handleCheckboxChange(reminder, index)">
                                            <label class="form-check-label" :for="`checkbox-${index}`">
                                                <div>{{ reminder.reminder }}</div>
                                                <div class="text-secondary"><small>Lisatud: {{ reminder.created_by }} {{
                formatTimestamp(reminder.created_on) }}</small></div>
                                            </label>
                                        </li>
                                    </ul>
                                    <div v-else class="fst-italic small">Meeldetuletused puuduvad</div>
                                    <div class="mt-3">
                                        <button class="btn btn-primary btn-sm" @click="openModal('reminder')">
                                            Lisa uus
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion shadow mt-3" id="accordionNeedsAttention"
                        v-if="task.object_id && [0, 1].includes(selectedStage)"
                        v-on="{ 'shown.bs.collapse': this.scrollToElement }">
                        <div class="accordion-item border-0">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed fs-6 fw-bolder" type="button"
                                    :class="{ 'text-body-tertiary': task.object_needs_attention.length == 0 }"
                                    data-bs-toggle="collapse" data-bs-target="#collapseNeedsAttention"
                                    aria-expanded="false" aria-controls="collapseNeedsAttention">
                                    Seadme puudused
                                </button>
                            </h2>
                            <div id="collapseNeedsAttention" class="accordion-collapse collapse"
                                data-bs-parent="#accordionNeedsAttention">
                                <div class="accordion-body">
                                    <ul v-if="task.object_needs_attention.length > 0"
                                        class="list-group list-group-flush">
                                        <li v-for="item in task.object_needs_attention" :key="item.id"
                                            class="my-2 list-group-item">
                                            <router-link :to="`/tasks/${item.id}`"
                                                class="text-decoration-none d-flex justify-content-between align-items-start">
                                                <div>
                                                    <div class="small fw-bolder">{{ item.name }}</div>
                                                    <div class="small my-1">{{ item.notes }}</div>
                                                    <div class="text-secondary"><small>{{ item.finished_by_name }} {{
                item.finished_on ? formatTimestamp(item.finished_on) : ''
            }}</small></div>
                                                </div>
                                                <div><i class="ms-2 fa-solid fa-triangle-exclamation"
                                                        :class="{ 'text-danger': item.needs_attention == 1, 'text-warning': item.needs_attention == 2, 'text-primary': item.needs_attention == 4 }"></i>
                                                </div>
                                            </router-link>
                                        </li>
                                    </ul>
                                    <div v-else class="fst-italic small">Puudused puuduvad</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion shadow mt-3" id="accordionContact" v-if="[0].includes(selectedStage)"
                        v-on="{ 'shown.bs.collapse': this.scrollToElement }">
                        <div class="accordion-item border-0">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed fs-6 fw-bolder" type="button"
                                    :class="{ 'text-body-tertiary': task.contacts.length == 0 && task.customer_contacts.length == 0 }"
                                    data-bs-toggle="collapse" data-bs-target="#collapseContact" aria-expanded="false"
                                    aria-controls="collapseContact">
                                    Kliendi kontaktandmed
                                </button>
                            </h2>
                            <div id="collapseContact" class="accordion-collapse collapse"
                                data-bs-parent="#accordionContact">
                                <div class="accordion-body">
                                    <div v-if="task.contacts.length > 0">
                                        <h6>Töö kontaktid</h6>
                                        <div v-for="contact in task.contacts" :key="contact.id">
                                            <ul class="list-unstyled">
                                                <li>
                                                    <i class="fa-regular fa-user text-danger"></i>
                                                    <span class="ps-2"><small>{{ contact.name }}</small></span>
                                                </li>
                                                <li>
                                                    <a :href="`tel:${contact.phone}`">
                                                        <i class="fa-solid fa-phone text-danger"></i>
                                                        <span class="ps-2"><small>{{ contact.phone }}</small></span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a :href="`mailto:${contact.email}`">
                                                        <i class="fa-regular fa-envelope text-danger"></i>
                                                        <span class="ps-2"><small>{{ contact.email }}</small></span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div v-if="task.customer_contacts.length > 0">
                                        <h6>Objektikaardi kontaktid</h6>
                                        <div v-for="contact in task.customer_contacts" :key="contact.id">
                                            <ul class="list-unstyled">
                                                <li>
                                                    <i class="fa-regular fa-user text-danger"></i>
                                                    <span class="ps-2"><small>{{ contact.name }}</small></span>
                                                </li>
                                                <li>
                                                    <a :href="`tel:${contact.phone}`">
                                                        <i class="fa-solid fa-phone text-danger"></i>
                                                        <span class="ps-2"><small>{{ contact.phone }}</small></span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a :href="`mailto:${contact.email}`">
                                                        <i class="fa-regular fa-envelope text-danger"></i>
                                                        <span class="ps-2"><small>{{ contact.email }}</small></span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div v-if="task.contacts.length == 0 && task.customer_contacts.length == 0"
                                        class="fst-italic small">Kontaktisikud puuduvad</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion shadow mt-3" id="accordionProjectContact" v-if="[0].includes(selectedStage)"
                        v-on="{ 'shown.bs.collapse': this.scrollToElement }">
                        <div class="accordion-item border-0">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed fs-6 fw-bolder" type="button"
                                    :class="{ 'text-body-tertiary': task.project_contacts.length == 0 }"
                                    data-bs-toggle="collapse" data-bs-target="#collapseProjectContact"
                                    aria-expanded="false" aria-controls="collapseProjectContact">
                                    Foruse kontaktandmed
                                </button>
                            </h2>
                            <div id="collapseProjectContact" class="accordion-collapse collapse"
                                data-bs-parent="#accordionProjectContact">
                                <div class="accordion-body">
                                    <div v-if="task.project_contacts.length > 0">
                                        <div v-for="contact in task.project_contacts" :key="contact.id">
                                            <h6>{{ contact.title }}</h6>
                                            <ul class="list-unstyled">
                                                <li>
                                                    <i class="fa-regular fa-user text-danger"></i>
                                                    <span class="ps-2"><small>{{ contact.name }}</small></span>
                                                </li>
                                                <li>
                                                    <a :href="`tel:${contact.phone}`">
                                                        <i class="fa-solid fa-phone text-danger"></i>
                                                        <span class="ps-2"><small>{{ contact.phone }}</small></span>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a :href="`mailto:${contact.email}`">
                                                        <i class="fa-regular fa-envelope text-danger"></i>
                                                        <span class="ps-2"><small>{{ contact.email }}</small></span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div v-else class="fst-italic small">Kontaktisikud puuduvad</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion shadow mt-3" id="accordionFiles" v-if="[0, 1].includes(selectedStage)"
                        v-on="{ 'shown.bs.collapse': this.scrollToElement }">
                        <div class="accordion-item border-0">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed fs-6 fw-bolder" type="button"
                                    :class="{ 'text-body-tertiary': task.technician_files.length == 0 }" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFiles" aria-expanded="false" aria-controls="collapseFiles">
                                    Manused
                                </button>
                            </h2>
                            <div id="collapseFiles" class="accordion-collapse collapse"
                                data-bs-parent="#accordionFiles">
                                <div class="accordion-body">
                                    <ul v-if="task.technician_files.length > 0" class="list-unstyled">
                                        <li class="my-2 list-group-item d-flex justify-content-between align-items-start" v-for="(file, fileIndex) in task.technician_files" :key="file.id">
                                            <div v-if="file.content_type == 1" class="btn btn-light w-100 text-start text-truncate small" @click="requestFile(file)"><i class="fa-solid fa-paperclip me-2"></i>{{ file.filename }}</div>
                                            <a v-else target="_blank" :href="file.url" role="button" class="btn btn-light w-100 text-start text-truncate small"><i class="fa-solid fa-at me-2"></i>{{ file.url }}</a>
                                            <button v-if="!task.finished" class="ms-2 btn-icon bg-danger-subtle text-danger" @click="deleteTaskFile(file, fileIndex)"><i class="fa-regular fa-trash-can"></i></button>
                                        </li>
                                    </ul>
                                    <div v-else class="fst-italic small">Manused puuduvad</div>
                                    <input type="file" @change="uploadFile" ref="fileUpload" style="display: none;">
                                    <button v-if="!task.finished" class="btn btn-sm btn-primary mt-3"
                                        @click="openFileDialog">
                                        <i class="fa-solid fa-arrow-up-from-bracket me-2"></i><small>Lisa fail</small>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion shadow mt-3" id="accordionLocation" v-if="[0].includes(selectedStage)"
                        v-on="{ 'shown.bs.collapse': this.scrollToElement }">
                        <div class="accordion-item border-0">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed fs-6 fw-bolder" type="button"
                                    :class="{ 'text-body-tertiary': task.files.project_address }"
                                    data-bs-toggle="collapse" data-bs-target="#collapseLocation" aria-expanded="false"
                                    aria-controls="collapseLocation">
                                    Asukoht
                                </button>
                            </h2>
                            <div id="collapseLocation" class="accordion-collapse collapse"
                                data-bs-parent="#accordionLocation">
                                <div class="accordion-body text-multiline">
                                    <div v-if="task.project_address">
                                        <div class="small my-3">Aadress: {{ task.project_address }}</div>
                                        <div class="row">
                                            <div class="col-auto">
                                                <a :href="googleMapsUrl" target="_blank"
                                                    class="btn btn-primary btn-sm w-100">
                                                    Google Maps
                                                </a>
                                            </div>
                                            <div class="col-auto">
                                                <a :href="wazeUrl" target="_blank" class="btn btn-primary btn-sm w-100">
                                                    Waze
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="task.project_coordinates">
                                        <div class="small my-3">Koordinaadid: {{ task.project_coordinates }}</div>
                                        <div class="row">
                                            <div class="col-auto">
                                                <a :href="googleMapsUrlCoordinates" target="_blank"
                                                    class="btn btn-primary btn-sm w-100">
                                                    Google Maps
                                                </a>
                                            </div>
                                            <div class="col-auto">
                                                <a :href="wazeUrlCoordinates" target="_blank" class="btn btn-primary btn-sm w-100">
                                                    Waze
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="!task.project_address && !task.project_coordinates" class="small fst-italic">
                                        Asukoht puudub
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <input type="file" @change="uploadSubtaskFile" ref="subtaskFileUpload" style="display: none;"
                        accept="image/*" capture="environment">

                    <div class="accordion shadow mt-3" id="accordionAct" v-if="[1].includes(selectedStage) && task.maintenance_acts.length > 0 && task.signed_files.length == 0"
                        v-on="{ 'shown.bs.collapse': this.scrollToElement }">
                        <div class="accordion-item border-0">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed fs-6 fw-bolder" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapseAct" aria-expanded="false"
                                    aria-controls="collapseAct">
                                    Akti vorm
                                </button>
                            </h2>
                            <div id="collapseAct" class="accordion-collapse collapse"
                                data-bs-parent="#accordionAct">
                                <div class="accordion-body p-0">
                                    <ul class="list-group list-group-flush">
                                        <li v-for="act in task.maintenance_acts" :key="act.id"
                                            class="list-group-item">
                                            <router-link :to="`/tasks/${task.id}/act/${act.id}`" class="text-decoration-none ms-auto">
                                                {{ act.name }}
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion shadow mt-3" id="accordionChecklists" v-if="[1].includes(selectedStage)"
                        v-on="{ 'shown.bs.collapse': this.scrollToElement }">
                        <div class="accordion-item border-0">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed fs-6 fw-bolder" type="button"
                                    :class="{ 'text-body-tertiary': task.subtasks.length == 0 }"
                                    data-bs-toggle="collapse" data-bs-target="#collapseChecklistsContent"
                                    aria-expanded="false" aria-controls="collapseChecklistsContent">
                                    Kontrollnimekiri
                                </button>
                            </h2>
                            <div id="collapseChecklistsContent" class="accordion-collapse collapse"
                                data-bs-parent="#accordionChecklists">
                                <div class="accordion-body">
                                    <div v-if="task.subtasks.length > 0">

                                        <div v-for="(subtask, index) in task.subtasks" :key="subtask.id">
                                            <div v-if="subtask.checklist_istitle">
                                                <div class="fw-bold mb-3">{{ subtask.name }}</div>
                                            </div>
                                            <div v-else>
                                                <div class="accordion mb-3" :id="'checklistAccordion' + subtask.id">
                                                    <div class="accordion-item"
                                                        :class="{ 'bg-success-subtle': subtask.finished, 'bg-danger-subtle': !subtask.finished }">
                                                        <h3 class="accordion-header">
                                                            <button class="accordion-button collapsed" type="button"
                                                                :class="{ 'bg-success-subtle': subtask.finished, 'bg-danger-subtle': !subtask.finished }"
                                                                :data-bs-toggle="'collapse'"
                                                                :data-bs-target="'#collapseNestedChecklist' + subtask.id"
                                                                aria-expanded="false"
                                                                :aria-controls="'collapseNestedChecklist' + subtask.id">
                                                                {{ subtask.name }}
                                                            </button>
                                                        </h3>
                                                        <div :id="'collapseNestedChecklist' + subtask.id"
                                                            class="accordion-collapse collapse"
                                                            :data-bs-parent="'#checklistAccordion' + subtask.id">
                                                            <div class="accordion-body">
                                                                <div class="mb-3">
                                                                    <label :for="'subTaskNotes' + subtask.id"
                                                                        class="form-label"><small>Kommentaar</small></label>
                                                                    <textarea :id="'subTaskNotes' + subtask.id"
                                                                        class="form-control bg-white"
                                                                        placeholder="Kommentaar"
                                                                        :disabled="subtask.finished || task.active_stage_id !== 1"
                                                                        v-model="subtask.notes"></textarea>
                                                                </div>

                                                                <button
                                                                    v-if="!task.finished && !subtask.finished && task.active_stage_id == 1"
                                                                    class="btn btn-primary"
                                                                    @click="openSubtaskFileDialog(subtask)">
                                                                    <i
                                                                        class="fa-solid fa-arrow-up-from-bracket me-2"></i><small>Lisa
                                                                        pilt</small>
                                                                </button>
                                                                <ul class="list-unstyled mt-3">
                                                                    <li class="my-2 list-group-item d-flex justify-content-between align-items-start"
                                                                        v-for="(file, fileIndex) in subtask.files"
                                                                        :key="file.id">
                                                                        <div class="btn btn-light w-100 text-start text-truncate"
                                                                            @click="requestSubtaskFile(subtask, file)">
                                                                            <i
                                                                                class="fa-solid fa-paperclip me-2"></i><small>{{
                file.filename }}</small>
                                                                        </div>
                                                                        <button
                                                                            v-if="!task.finished && !subtask.finished && task.active_stage_id == 1"
                                                                            class="ms-2 btn-icon bg-danger-subtle text-danger"
                                                                            @click="deleteSubtaskFile(subtask, file, fileIndex)">
                                                                            <i class="fa-regular fa-trash-can"></i>
                                                                        </button>
                                                                    </li>
                                                                </ul>
                                                                <div class="row mb-3">
                                                                    <div class="col">
                                                                        <div class="form-check">
                                                                            <input
                                                                                :id="'subTaskNeedsAttention' + subtask.id"
                                                                                type="checkbox" class="form-check-input"
                                                                                :checked="subtask.needs_attention > 0"
                                                                                :disabled="subtask.finished || task.active_stage_id !== 1">
                                                                            <label class="form-check-label"
                                                                                :for="'subTaskNeedsAttention' + subtask.id">Puudus</label>
                                                                        </div>

                                                                    </div>
                                                                    <div class="col text-end">
                                                                        <div v-if="subtask.needs_attention > 0">
                                                                            <span
                                                                                class="badge rounded-pill bg-light py-2 px-3">
                                                                                <i class="fa-solid fa-triangle-exclamation"
                                                                                    :class="{ 'text-danger': subtask.needs_attention === 1, 'text-warning': subtask.needs_attention === 2, 'text-success': subtask.needs_attention === 3, 'text-primary': subtask.needs_attention === 4 }">
                                                                                </i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="mb-3">
                                                                    <div class="form-check">
                                                                        <input :id="'subTaskFinished' + subtask.id"
                                                                            type="checkbox" class="form-check-input"
                                                                            @change="updateSubtask(subtask, index)"
                                                                            v-model="subtask.finished"
                                                                            :disabled="task.active_stage_id !== 1">
                                                                        <label class="form-check-label"
                                                                            :for="'subTaskFinished' + subtask.id">Lõpetatud</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="small fst-italic">
                                        Kontrollnimekiri puudub
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion shadow mt-3" id="accordionMaterials" v-if="[1, 2].includes(selectedStage)"
                        v-on="{ 'shown.bs.collapse': this.scrollToElement }">
                        <div class="accordion-item border-0">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed fs-6 fw-bolder" type="button"
                                    :class="{ 'text-body-tertiary': this.materials.length == 0 }"
                                    data-bs-toggle="collapse" data-bs-target="#collapseMaterials" aria-expanded="false"
                                    aria-controls="collapseMaterials">
                                    Materjalid
                                </button>
                            </h2>
                            <div id="collapseMaterials" class="accordion-collapse collapse"
                                data-bs-parent="#accordionMaterials">
                                <div class="accordion-body">
                                    <ul v-if="this.materials.length > 0" class="list-group list-group-flush">
                                        <li v-for="resource in this.materials" :key="resource.id"
                                            class="list-group-item d-flex justify-content-between align-items-start px-0">
                                            <div class="me-auto">
                                                <div class="fw-bold">{{ resource.resource_name }}, {{ resource.amount }}tk</div>
                                                <div class="text-muted text-multiline"><small>{{ resource.sn }}</small></div>
                                                <div class="text-muted text-multiline"><small>{{ resource.description }}</small></div>
                                                <div class="text-muted"><small>{{ formatTimestamp(resource.issued_on) }}</small></div>
                                            </div>
                                            <button v-if="!task.finished && task.active_stage_id >= 1 && task.signed_files.length == 0" class="btn-icon"
                                                @click="openEditModal('materials', resource)"><i
                                                    class="fa-solid fa-pencil fa-fw"></i></button>
                                        </li>
                                    </ul>
                                    <div v-else class="small fst-italic">
                                        Materjalid puuduvad
                                    </div>
                                    <div v-if="!task.finished && task.active_stage_id >= 1 && task.signed_files.length == 0" class="mt-3">
                                        <button class="btn btn-primary btn-sm" @click="openModal('materials')">
                                            Lisa materjal ja seadme müük
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion shadow mt-3" id="accordionHours" v-if="[1, 2].includes(selectedStage)"
                        v-on="{ 'shown.bs.collapse': this.scrollToElement }">
                        <div class="accordion-item border-0">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed fs-6 fw-bolder" type="button"
                                    :class="{ 'text-body-tertiary': this.hours.length == 0 }" data-bs-toggle="collapse"
                                    data-bs-target="#collapseHours" aria-expanded="false" aria-controls="collapseHours">
                                    Aeg
                                </button>
                            </h2>
                            <div id="collapseHours" class="accordion-collapse collapse"
                                data-bs-parent="#accordionHours">
                                <div class="accordion-body">
                                    <div v-if="task.max_quote_points !== null" class="mb-3"><span
                                            class="small fw-medium">Eelarvestatud punktid:</span> <span class="small">{{
                task.max_quote_points }}</span></div>
                                    <ul v-if="this.hours.length > 0" class="list-group list-group-flush">
                                        <li v-for="resource in this.hours" :key="resource.id"
                                            class="list-group-item d-flex justify-content-between align-items-start px-0">
                                            <div class="me-auto">
                                                <div class="fw-bold">{{ resource.resource_name }}, {{ resource.amount
                                                    }}h
                                                </div>
                                                <div class="text-muted text-multiline"><small>{{ resource.description
                                                        }}</small>
                                                </div>
                                                <div class="text-muted"><small>{{ formatTimestamp(resource.issued_on)
                                                        }}</small>
                                                </div>
                                            </div>
                                            <button v-if="!task.finished && task.active_stage_id >= 1 && task.signed_files.length == 0" class="btn-icon"
                                                @click="openEditModal('hours', resource)"><i
                                                    class="fa-solid fa-pencil fa-fw"></i></button>
                                        </li>
                                    </ul>
                                    <div v-else class="small fst-italic">
                                        Sissekanded puuduvad
                                    </div>
                                    <div v-if="!task.finished && task.active_stage_id >= 1 && task.signed_files.length == 0" class="mt-3">
                                        <button class="btn btn-primary btn-sm" @click="openModal('hours')">
                                            Lisa aeg või lisatöö
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion shadow mt-3" id="accordionInfo" v-if="[0, 1].includes(selectedStage)"
                        v-on="{ 'shown.bs.collapse': this.scrollToElement }">
                        <div class="accordion-item border-0">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed fs-6 fw-bolder" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapseInfo" aria-expanded="false"
                                    aria-controls="collapseInfo">
                                    Info tehnikule
                                </button>
                            </h2>
                            <div id="collapseInfo" class="accordion-collapse collapse" data-bs-parent="#accordionInfo">
                                <div class="accordion-body">
                                    <div v-if="simsAccounts.length > 0">
                                        <div v-for="simsAccount in simsAccounts" :key="simsAccount.id"
                                            class="card mb-3 bg-light-subtle">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col">
                                                        <div class="form-check">
                                                            <input :id="'simsAccount' + simsAccount.id" type="checkbox"
                                                                class="form-check-input"
                                                                v-model="selectedSimsAccounts"
                                                                :value="simsAccount.id"
                                                                :disabled="task.finished || task.active_stage_id !== 1">
                                                            <label class="form-check-label"
                                                                :for="'simsAccount' + simsAccount.id"><span
                                                                    class="extra-small">{{ simsAccount.sims_account
                                                                    }}</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col">
                                                        <span
                                                            :class="{ 
                                                                'text-bg-light': simsAccount.out_of_service === false, 
                                                                'bg-info-subtle text-info': simsAccount.out_of_service === true,
                                                                'bg-danger-subtle text-danger': simsAccount.out_of_service === null,
                                                            }"
                                                            class="badge rounded-pill me-1">
                                                                {{ simsAccount.out_of_service === null ? 'Signaal puudub' : (simsAccount.out_of_service ? formatTimestamp(simsAccount.out_of_service_until) : 'Pole hoolduses') }}
                                                            </span>
                                                    </div>
                                                    <div class="col extra-small fw-medium d-flex align-items-center">
                                                        <router-link :to="`/projects/${simsAccount.project_id}/sims-accounts/${simsAccount.id}/signals`" class="text-decoration-none ms-auto">
                                                            <span>SIGNAALID<i class="fa-solid fa-arrow-right ms-1"></i></span>
                                                        </router-link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <VueDatePicker v-model="outOfServiceUntil" time-picker input-class-name=""
                                            locale="et" cancelText="Loobu" selectText="Vali" :clearable="false" :disabled="task.finished || task.active_stage_id !== 1"
                                            required />

                                        <div class="my-3 d-flex justify-content-between">
                                                <button class="btn btn-primary btn-sm" @click="setOutOfService()" :disabled="selectedSimsAccounts.length == 0 || task.finished || task.active_stage_id !== 1">
                                                    Hooldusesse
                                                </button>
                                                <button class="btn btn-primary btn-sm" @click="deleteOutOfService()" :disabled="selectedSimsAccounts.length == 0 || task.finished || task.active_stage_id !== 1">
                                                    Hooldusest maha
                                                </button>
                                        </div>
                                    </div>
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item p-0">
                                            <h6>Vastutavad isikud</h6>
                                            <div><small>Vastutaja: {{ task.directed_to_name }}</small></div>
                                            <div><small>Töö tegijad: {{ task.users.map(user => user.name).join(', ')
                                                    }}</small>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion shadow mt-3" id="accordionFinalize" v-if="[1, 2].includes(selectedStage)"
                        v-on="{ 'shown.bs.collapse': this.scrollToElement }">
                        <div class="accordion-item border-0">
                            <h2 class="accordion-header">
                                <button class="accordion-button fs-6 fw-bolder" type="button"
                                    :class="{ 'text-body-tertiary': !task.notes2 && task.signed_files.length == 0 }"
                                    data-bs-toggle="collapse" data-bs-target="#collapseFinalize" aria-expanded="false"
                                    aria-controls="collapseFinalize">
                                    Tehtud töö
                                </button>
                            </h2>
                            <div id="collapseFinalize" class="accordion-collapse"
                                data-bs-parent="#accordionFinalize">
                                <div class="accordion-body">
                                    <div class="mb-3">
                                        <label for="notes2Input" class="form-label"><small>Tööde kirjeldus
                                                aktile</small></label>
                                        <textarea id="notes2Input" class="form-control" v-model="task.notes2"
                                            placeholder="Kirjeldus"
                                            :disabled="task.signed_files.length > 0 || task.finished || task.active_stage_id < 1"></textarea>
                                    </div>
                                    <ul v-if="task.signed_files.length > 0" class="list-unstyled">
                                        <li class="my-2 list-group-item d-flex justify-content-between align-items-start"
                                            v-for="file in task.signed_files" :key="file.id">
                                            <div class="btn btn-light w-100 text-start text-truncate"
                                                @click="requestFile(file)"><i
                                                    class="fa-solid fa-paperclip me-2"></i><small>{{ file.filename
                                                    }}</small></div>
                                        </li>
                                    </ul>
                                    <div class="mt-3">
                                        <button v-if="task.signed_files.length == 0 && !task.finished && task.active_stage_id >= 1" class="btn btn-primary btn-sm me-3" @click="saveNotes2">
                                            Salvesta
                                        </button>
                                        <button v-if="task.signed_files.length == 0 && !task.finished && task.active_stage_id == 2" class="btn btn-primary btn-sm" @click="openModal('signature')">
                                            Genereeri PDF ja võta allkiri
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion shadow mt-3" id="accordionEmail" v-if="[2].includes(selectedStage)"
                        v-on="{ 'shown.bs.collapse': this.scrollToElement }">
                        <div class="accordion-item border-0">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed fs-6 fw-bolder" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapseEmail" aria-expanded="false"
                                    aria-controls="collapseEmail">
                                    Saada kliendile
                                </button>
                            </h2>
                            <div id="collapseEmail" class="accordion-collapse collapse"
                                data-bs-parent="#accordionEmail">
                                <div class="accordion-body">
                                    <div class="mb-3">
                                        <label for="emailInput" class="form-label"><small>E-mail</small></label>
                                        <input type="email" v-model="email" id="emailInput" class="form-control"
                                            :disabled="task.signed_files.length == 0 || task.finished || task.active_stage_id !== 2">
                                    </div>
                                    <div class="mb-3">
                                        <label for="emailBodyInput" class="form-label"><small>E-maili
                                                sisu</small></label>
                                        <textarea id="emailBodyInput" class="form-control" v-model="emailBody"
                                            placeholder="Sisu"
                                            :disabled="task.signed_files.length == 0 || task.finished || task.active_stage_id !== 2"></textarea>
                                    </div>
                                    <div class="mt-3"
                                        v-if="task.signed_files.length > 0 && !task.finished && task.active_stage_id == 2">
                                        <button class="btn btn-primary btn-sm" @click="sendMail">
                                            Saada kiri
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion shadow mt-3" id="accordionNewTask" v-if="[2].includes(selectedStage)"
                        v-on="{ 'shown.bs.collapse': this.scrollToElement }">
                        <div class="accordion-item border-0">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed fs-6 fw-bolder" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapseNewTask" aria-expanded="false"
                                    aria-controls="collapseNewTask">
                                    Uus töö
                                </button>
                            </h2>
                            <div id="collapseNewTask" class="accordion-collapse collapse"
                                data-bs-parent="#accordionNewTask">
                                <div class="accordion-body">
                                    <div class="mb-3">
                                        <label for="newTaskTitleInput" class="form-label"><small>Pealkiri</small></label>
                                        <input type="text" v-model="newTaskTitle" id="newTaskTitleInput" class="form-control">
                                    </div>
                                    <div class="mt-3">
                                        <button class="btn btn-primary btn-sm" @click="addNewTask">
                                            Loo uus töö
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="accordion shadow mt-3" id="accordionComments" v-if="[0, 1, 2].includes(selectedStage)"
                        v-on="{ 'shown.bs.collapse': this.scrollToElement }">
                        <div class="accordion-item border-0">
                            <h2 class="accordion-header">
                                <button class="accordion-button fs-6 fw-bolder" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseComments"
                                    :aria-expanded="isCommentExpanded ? 'true' : 'false'"
                                    aria-controls="collapseComments"
                                    :class="{ 'collapsed': !isCommentExpanded, 'text-body-tertiary': task.comments.length == 0 }">
                                    Kommentaarid
                                </button>
                            </h2>
                            <div id="collapseComments" class="accordion-collapse" data-bs-parent="#accordionComments"
                                :class="{ 'collapse': !isCommentExpanded, 'show': isCommentExpanded }">
                                <div class="accordion-body">
                                    <div v-if="task.comments.length > 0">
                                        <div class="card mb-3 bg-light" v-for="(comment) in task.comments"
                                            :key="comment.id">
                                            <ul class="list-group list-group-flush">
                                                <li class="list-group-item bg-light p-3" :id="'comment-' + comment.id"
                                                    :class="{ 'bg-warning-subtle': isCommentTarget(comment.id) }">
                                                    <h6>{{ comment.user_name }}</h6>
                                                    <div class="text-multiline" v-html="comment.comment"></div>
                                                    <div class="row mt-2">
                                                        <div class="col small text-muted">
                                                            {{ formatTimestamp(comment.created_on) }}
                                                        </div>
                                                        <div class="col-auto small text-end">
                                                            <span role="button" @click="openCommentModal(comment)"><i
                                                                    class="fa-regular fa-comment me-2"></i>Vasta</span>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="list-group-item bg-light-subtle p-3 ps-5"
                                                    v-for="(reply) in comment.replies" :key="reply.id"
                                                    :id="'comment-' + reply.id"
                                                    :class="{ 'bg-warning-subtle': isCommentTarget(comment.id) }">
                                                    <h6>{{ reply.user_name }}</h6>
                                                    <div v-html="reply.comment"></div>
                                                    <div class="small text-muted mt-2">{{
                formatTimestamp(reply.created_on) }}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div v-else class="small fst-italic">
                                        Kommentaarid puuduvad
                                    </div>
                                    <div class="mt-3">
                                        <button class="btn btn-primary btn-sm" @click="openModal('comment')">
                                            Lisa uus kommentaar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="fixed-bottom bg-primary p-3 mt-3 shadow">
                        <div v-if="!isUserInvolved" class="row">
                            <div class="col">
                                <button class="btn border-1 border-white text-white w-100" @click="goBack()">
                                    Tagasi
                                </button>
                            </div>
                            <div v-if="!task.finished && !isUserInvolved" class="col">
                                <button class="btn btn-light w-100" @click="addMe">Lisa mind</button>
                            </div>
                        </div>
                        <div v-else-if="!task.finished" class="row">
                            <div v-if="task && task.actions && task.actions.length <= 1" class="col">
                                <button class="btn border-1 border-white text-white w-100" @click="goBack()">
                                    Tagasi
                                </button>
                            </div>
                            <div class="col" v-for="action in task.actions" :key="action.name">
                                <button class="btn w-100" @click="performAction(action.name)"
                                    :class="{ 'border-1 border-white text-white': action.pause, 'btn-light': !action.pause }">
                                    {{ action.title }}
                                </button>
                            </div>
                        </div>
                        <div v-else class="row">
                            <div class="col">
                                <button class="btn border-1 border-white text-white w-100" @click="goBack()">
                                    Tagasi
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <NotFound v-else :item="'töö'" />
            </div>
        </div>
    </div>
    <div v-if="showModal" class="item-modal bg-light">
        <div class="modal-content">
            <div v-if="modalType === 'hours' || modalType === 'materials'">
                <add-item-modal :type="modalType" :taskId="task.id" @close="showModal = false" :itemToEdit="currentItem" :profileId="task.profile_id" :priorityId="task.priority_id" :accountingstatus_id="task.accountingstatus_id"
                    @item-added="addItem" @item-deleted="handleItemDeleted" @item-updated="updateItem">
                </add-item-modal>
            </div>
            <div v-else-if="modalType === 'reminder'">
                <add-reminder-modal :objectId="task.object_id" @close="showModal = false" @reminder-added="addReminder">
                </add-reminder-modal>
            </div>
            <div v-else-if="modalType === 'comment'">
                <add-comment-modal :taskId="task.id" @close="showModal = false" @comment-added="addComment"
                    :originalComment="currentItem">
                </add-comment-modal>
            </div>
            <div v-else-if="modalType === 'signature'">
                <add-signature-modal :taskId="task.id" @close="showModal = false" @signature-added="addSignature"
                    :notes2="task.notes2" :materials="this.materials" :hours="this.hours">
                </add-signature-modal>
            </div>
        </div>
    </div>

</template>

<script>
import axios from '@/services/axios';
import { useFormatTimestamp } from '@/composables/useFormatTimestamp';
import TaskComponent from '@/components/TaskComponent.vue';
import ProgressSteps from '@/components/ProgressSteps.vue';
import AddItemModal from '@/components/AddItemModal.vue';
import AddReminderModal from '@/components/AddReminderModal.vue';
import AddCommentModal from '@/components/AddCommentModal.vue';
import AddSignatureModal from '@/components/AddSignatureModal.vue';
import NotFound from '@/components/NotFound.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
    data() {
        return {
            task: null,
            reminders: [],
            simsAccounts: [],
            selectedSimsAccounts: [],
            selectedStage: null,
            showModal: false,
            modalType: null,
            currentItem: null,
            currentSubtaskId: null,
            emailBody: null,
            email: null,
            loading: true,
            newTaskTitle: null,
            outOfServiceUntil: {
                hours: this.setDefaultTime().getHours(),
                minutes: this.setDefaultTime().getMinutes()
            },
        };
    },
    components: {
        TaskComponent,
        ProgressSteps,
        AddItemModal,
        AddReminderModal,
        AddCommentModal,
        NotFound,
        AddSignatureModal,
        VueDatePicker
    },
    setup() {
        const { formatTimestamp } = useFormatTimestamp();
        return { formatTimestamp };
    },
    mounted() {
        this.fetchTaskDetail();
    },
    watch: {
        '$route'(to, from) {
            // Check if the route parameter you're interested in has changed
            if (to.params.taskId !== from.params.taskId) {
                // Call the method to fetch new task data
                this.fetchTaskDetail();
            }
        }
    },
    methods: {
        formatDate(date) {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },
        async setOutOfService() {
            const currentTime = new Date();
            let serviceTime = new Date();

            serviceTime.setHours(this.outOfServiceUntil.hours, this.outOfServiceUntil.minutes, 0);

            // If the selected time is in the past, move it to the next day
            if (serviceTime <= currentTime) {
                serviceTime.setDate(serviceTime.getDate() + 1);
            }

            // Custom format the service time to "YYYY-MM-DD HH:mm:ss"
            const formattedServiceTime = this.formatDate(serviceTime);

            for (let accountId of this.selectedSimsAccounts) {
                try {
                    await axios.post(`/projects/${this.task.project_id}/sims-accounts/${accountId}/set-out-of-service`, {
                        outOfServiceUntil: formattedServiceTime,
                    });
                    // Handle successful response
                } catch (error) {
                    console.error('Error setting out of service:', error);
                    // Handle error
                }
            }
            this.fetchSimsAccounts();
        },
        async deleteOutOfService() {
            for (let accountId of this.selectedSimsAccounts) {
                try {
                    await axios.post(`/projects/${this.task.project_id}/sims-accounts/${accountId}/delete-out-of-service`);
                    // Handle successful response
                } catch (error) {
                    console.error('Error deleting out of service:', error);
                    // Handle error
                }
            }
            this.fetchSimsAccounts();
        },
        setDefaultTime() {
            let now = new Date();
            now.setHours(17, 0, 0, 0); // Sets the time to 17:00
            return now;
        },
        isCommentTarget(commentId) {
            // Extract the comment ID from the hash
            const hashCommentId = this.$route.hash.replace('#comment-', '');
            return commentId.toString() === hashCommentId;
        },
        scrollToComment(commentId) {
            const element = document.getElementById(`comment-${commentId}`);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        },
        openSubtaskFileDialog(subtask) {
            this.currentSubtaskId = subtask.id; // Store the subtask ID
            this.$refs.subtaskFileUpload.click(); // Open the file dialog
        },
        async uploadSubtaskFile(event) {
            const file = event.target.files[0];
            if (!file) return;

            const maxFileSize = 10 * 1024 * 1024;
            if (file.size > maxFileSize) {
                console.error('File size exceeds the maximum limit of 5 MB.');
                this.$root.$refs.globalAlert.showAlert('Max filesize 10mb', 'danger');
                // Handle the error, maybe show an alert to the user
                return;
            }

            const formData = new FormData();
            formData.append('file', file);

            // Use currentSubtaskId to determine where to upload the file
            try {
                const response = await axios.post(`tasks/${this.task.id}/subtasks/${this.currentSubtaskId}/files`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                const newFile = response.data.data;
                // Find the subtask in the task.subtasks array
                const subtaskIndex = this.task.subtasks.findIndex(subtask => subtask.id === this.currentSubtaskId);
                if (subtaskIndex !== -1) {
                    // Push the new file to the subtask's files array
                    this.task.subtasks[subtaskIndex].files.push(newFile);
                }
            } catch (error) {
                console.error('Error uploading file to subtask:', error);
            }

            // Reset currentSubtaskId and file input for next upload
            this.currentSubtaskId = null;
            event.target.value = ''; // Reset the file input
        },
        async addMe() {
            const storedUser = localStorage.getItem('user');
            if (!storedUser) return;

            try {
                const userId = JSON.parse(storedUser).id;
                const response = await axios.patch(`tasks/${this.task.id}`, {
                    users: [userId] // Append userId to existing users array
                });

                // Check response status and update task.users in state if necessary
                if (response.status === 200) {
                    this.fetchTaskDetail();
                    console.log("User successfully added to the task");
                }
            } catch (error) {
                console.error("Error in adding user to task", error);
                // Optionally handle error (e.g., display error message)
            }
        },
        async saveNotes2() {
            try {
                const response = await axios.patch(`tasks/${this.task.id}`, {
                    notes2: this.task.notes2
                });

                if (response.status === 200) {
                    this.$root.$refs.globalAlert.showAlert('Salvestatud', 'success');
                    console.log("Notes2 saved");
                }
            } catch (error) {
                console.error("Error saving notes2", error);
                this.$root.$refs.globalAlert.showAlert('Salvestamine ebaõnnestus', 'danger');
                // Optionally handle error (e.g., display error message)
            }
        },
        openModal(type) {
            this.currentItem = null;
            this.modalType = type;
            this.showModal = true;
        },
        async sendMail() {
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            if (!emailRegex.test(this.email)) {
                this.$root.$refs.globalAlert.showAlert('Email vigane', 'danger');
                return; // Stop the function execution
            }
            try {
                const response = await axios.post(`tasks/${this.task.id}/email`, {
                    body: this.emailBody,
                    email: this.email
                });

                // Check response status and update task.users in state if necessary
                if (response.status === 200) {
                    this.fetchTaskDetail();
                    this.$root.$refs.globalAlert.showAlert('Kiri saadetud', 'success');
                    console.log("Email sent");
                    this.emailBody = null;
                    this.email = null;
                }
            } catch (error) {
                console.error("Error sending email", error);
                this.$root.$refs.globalAlert.showAlert('Kirja saatmine ebaõnnestus', 'danger');
                // Optionally handle error (e.g., display error message)
            }
        },
        async addNewTask() {
            if (!this.newTaskTitle) {
                this.$root.$refs.globalAlert.showAlert('Pealkiri kohustuslik', 'danger');
                return; // Stop the function execution
            }
            try {
                const response = await axios.post(`tasks/${this.task.id}/copy`, {
                    title: this.newTaskTitle
                });

                // Check response status and update task.users in state if necessary
                if (response.status === 200) {
                    this.fetchTaskDetail();
                    this.$root.$refs.globalAlert.showAlert('Uus töö loodud', 'success');
                    console.log("Task created");
                }
            } catch (error) {
                console.error("Error creating task", error);
                this.$root.$refs.globalAlert.showAlert('Töö loomine ebaõnnestus', 'danger');
                // Optionally handle error (e.g., display error message)
            }
        },
        addItem(item) {
            this.task.resources.push(item);
            this.refreshResourcesLists();
        },
        addComment() {
            this.fetchTaskDetail();
        },
        addSignature() {
            this.fetchTaskDetail();
        },
        addReminder(item) {
            this.reminders.push(item);
        },
        openEditModal(type, item) {
            this.modalType = type;
            this.currentItem = item; // Set the current item to be edited
            this.showModal = true; // Open the modal
        },
        openCommentModal(item) {
            this.modalType = 'comment';
            this.currentItem = item; // Set the current item to be edited
            this.showModal = true; // Open the modal
        },
        handleItemDeleted(deletedItemId) {
            this.task.resources = this.task.resources.filter(item => item.id !== deletedItemId);
            this.refreshResourcesLists();
        },
        updateItem(updatedItem) {
            // Find the index of the item in the main resources list
            const index = this.task.resources.findIndex(item => item.id === updatedItem.id);

            // Replace the old item with the updated item
            if (index !== -1) {
                this.task.resources.splice(index, 1, updatedItem);
            }

            // Refresh materials and hours lists
            this.refreshResourcesLists();
        },
        refreshResourcesLists() {
            this.materials = this.task.resources.filter(resource => resource.is_material_resource);
            this.hours = this.task.resources.filter(resource => !resource.is_material_resource);
        },
        goBack() {
            if (this.$route.meta.internalNavigation && window.history.length > 2) {
                this.$router.go(-1);
            } else {
                this.$router.push('/');
            }
        },
        async fetchTaskDetail() {
            try {
                const taskId = this.$route.params.taskId;
                const [taskResponse] = await Promise.all([
                    axios.get(`tasks/${taskId}`)
                ]);

                this.task = taskResponse.data.data;

                this.materials = this.task.resources.filter(resource => resource.is_material_resource);
                this.hours = this.task.resources.filter(resource => !resource.is_material_resource);

                this.selectedStage = this.task.active_stage_id;
                if (this.task.object_id) {
                    this.fetchReminders();
                }
                if (this.task.project_id) {
                    this.fetchSimsAccounts();
                }
                this.loading = false;
                this.$nextTick(() => {
                    const commentId = this.$route.hash.slice(1);
                    if (commentId) {
                        const element = document.getElementById(commentId);
                        if (element) {
                            element.scrollIntoView({ behavior: 'smooth' });
                            const urlWithoutHash = window.location.href.split('#')[0];
                            window.history.replaceState(null, null, urlWithoutHash);
                        }
                    }
                });
            } catch (error) {
                console.error('Error fetching task detail:', error);
            }
        },
        async fetchReminders() {
            try {
                const remindersResponse = await axios.get(`objects/${this.task.object_id}/reminders`);
                this.reminders = remindersResponse.data.data;
            } catch (error) {
                console.error('Error fetching reminders:', error);
            }
        },

        async fetchSimsAccounts() {
            try {
                const simsAccountsResponse = await axios.get(`projects/${this.task.project_id}/sims-accounts`);
                this.simsAccounts = simsAccountsResponse.data.data;
            } catch (error) {
                console.error('Error fetching SIMs accounts:', error);
            }
        },
        async updateSubtask(subtask, index) {
            if (subtask) {
                try {
                    subtask.needs_attention = (subtask.needs_attention) > 0 ? true : false;
                    const response = await axios.patch(`tasks/${this.task.id}/subtasks/${subtask.id}/`, subtask);
                    // Update the subtask in your local state with the response
                    const updatedSubtask = response.data.data;

                    if (index !== -1) {
                        this.task.subtasks.splice(index, 1, updatedSubtask);
                    }
                } catch (error) {
                    console.error('Error updating subtask:', error);
                }
            }
        },
        async performAction(actionName) {
            // Ensure task and task ID are available
            if (!this.task || !this.task.id) {
                console.error('Task or Task ID is missing');
                return;
            }
            if (actionName === 'finish' && this.task.signed_files.length === 0) {
                const isConfirmed = window.confirm('Kas olete kindel, et soovite ülesande lõpetada allkirjastamata?');
                if (!isConfirmed) {
                    return; // Stop the action if the user cancels
                }
            }
            // Construct the API endpoint
            const apiEndpoint = `tasks/${this.task.id}/${actionName}`;

            try {
                // Make the API call
                await axios.post(apiEndpoint);
                await this.fetchTaskDetail(); // Fetch the task again
            } catch (error) {
                // Handle network or other errors
                console.error('Error performing action:', error);
            }
        },
        async deleteTaskFile(file, index) {

            try {
                const response = await axios.delete(`tasks/${this.task.id}/files/${file.id}`);
                if (response.status === 200) {
                    // Find the subtask in the task's subtasks list

                    // Remove the file from the subtask's files list
                    this.task.technician_files.splice(index, 1);
                }
            } catch (error) {
                console.error('Error removing file:', error);
            }

        },
        async deleteSubtaskFile(subtask, file, index) {

            try {
                const response = await axios.delete(`tasks/${this.task.id}/subtasks/${subtask.id}/files/${file.id}`);
                if (response.status === 200) {
                    // Find the subtask in the task's subtasks list
                    const subtaskIndex = this.task.subtasks.findIndex(s => s.id === subtask.id);
                    if (subtaskIndex !== -1) {
                        // Remove the file from the subtask's files list
                        this.task.subtasks[subtaskIndex].files.splice(index, 1);
                    }
                }
            } catch (error) {
                console.error('Error removing file:', error);
            }

        },
        async handleCheckboxChange(reminder, index) {
            if (reminder.checked) {
                try {
                    const response = await axios.delete(`objects/${this.task.object_id}/reminders/${reminder.id}`);
                    if (response.status === 200) {
                        this.reminders.splice(index, 1); // Remove the reminder from the list
                    } else {
                        // If the request fails and does not return a status of 200
                        reminder.checked = false; // Revert the checkbox to its original state
                    }
                } catch (error) {
                    console.error('Error removing reminder:', error);
                    reminder.checked = false; // Revert the checkbox to its original state
                }
            }
        },
        async requestFile(file) {
            try {
                const response = await axios({
                    url: `tasks/${this.task.id}/files/${file.id}`, // API endpoint to get the file
                    method: 'GET',
                    responseType: 'blob', // Important for files
                });

                // Create a URL for the blob
                const fileURL = window.URL.createObjectURL(new Blob([response.data]));

                // Create a link to download it
                const link = document.createElement('a');
                link.href = fileURL;
                link.setAttribute('download', file.filename);
                document.body.appendChild(link);
                link.click();

                // Clean up
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(fileURL);
            } catch (error) {
                console.error('Error fetching file:', error);
            }
        },
        async requestProjectFile(file) {
            try {
                const response = await axios({
                    url: `projects/${this.task.project_id}/files/${file.id}`, // API endpoint to get the file
                    method: 'GET',
                    responseType: 'blob', // Important for files
                });

                // Create a URL for the blob
                const fileURL = window.URL.createObjectURL(new Blob([response.data]));

                // Create a link to download it
                const link = document.createElement('a');
                link.href = fileURL;
                link.setAttribute('download', file.filename);
                document.body.appendChild(link);
                link.click();

                // Clean up
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(fileURL);
            } catch (error) {
                console.error('Error fetching file:', error);
            }
        },
        async requestSubtaskFile(subtask, file) {
            try {
                const response = await axios({
                    url: `tasks/${this.task.id}/subtasks/${subtask.id}/files/${file.id}`, // API endpoint to get the file
                    method: 'GET',
                    responseType: 'blob', // Important for files
                });

                // Create a URL for the blob
                const fileURL = window.URL.createObjectURL(new Blob([response.data]));

                // Create a link to download it
                const link = document.createElement('a');
                link.href = fileURL;
                link.setAttribute('download', file.filename);
                document.body.appendChild(link);
                link.click();

                // Clean up
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(fileURL);
            } catch (error) {
                console.error('Error fetching file:', error);
            }
        },
        openFileDialog() {
            this.$refs.fileUpload.click(); // Triggers the file input dialog
        },
        async uploadFile(event) {
            const file = event.target.files[0];
            if (!file) return;

            const maxFileSize = 10 * 1024 * 1024;
            if (file.size > maxFileSize) {
                console.error('File size exceeds the maximum limit of 5 MB.');
                this.$root.$refs.globalAlert.showAlert('Max filesize 10mb', 'danger');
                // Handle the error, maybe show an alert to the user
                return;
            }

            const formData = new FormData();
            formData.append('file', file);

            try {
                const response = await axios.post(`tasks/${this.task.id}/files`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                // Assuming the response data has the file object in 'data'
                const newFile = response.data.data;
                this.task.technician_files.push(newFile); // Append the new file to the existing list

                // Optionally, clear the input field
                event.target.value = null;

            } catch (error) {
                console.error('Error uploading file:', error);
            }
        },
        handleUpdateEstimatedDate(data) {
            this.task.estimated_date = data.newEndDate;
            this.task.start_date = (data.timed_due_date ? data.newDate : null);
            this.task.timed_due_date = data.timed_due_date;
            this.task.agreed = data.agreed;
        },
        scrollToElement(event) {
            this.$nextTick(() => {
                const element = document.getElementById(event.target.id);
                if (element) {
                    const offsetTop = element.getBoundingClientRect().top + window.pageYOffset;
                    window.scrollTo({ top: offsetTop - 150, behavior: 'smooth' }); // 50px above the element
                }
            });
        },
    },
    computed: {
        googleMapsUrl() {
            return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(this.task.project_address)}`;
        },
        wazeUrl() {
            return `https://waze.com/ul?q=${encodeURIComponent(this.task.project_address)}`;
        },
        googleMapsUrlCoordinates() {
            return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(this.task.project_coordinates)}`;
        },
        wazeUrlCoordinates() {
            return `https://waze.com/ul?q=${encodeURIComponent(this.task.project_coordinates)}`;
        },
        isUserInvolved() {
            // Check if user data is available in local storage
            const storedUser = localStorage.getItem('user');
            if (!storedUser) return false;

            const userId = JSON.parse(storedUser).id;

            // Check if 'users' array is defined in the task and contains the current user ID
            return this.task && ((Array.isArray(this.task.users) && this.task.users.some(user => user.id === userId)));
        },
        isCommentExpanded() {
            const commentHash = this.$route.hash;
            // Check if the hash starts with "#comment-" which indicates a comment ID is present
            return commentHash.startsWith("#comment-");
        },
    }

};
</script>

<style scoped>

.item-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    border-radius: 5px;
    z-index: 1031;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    max-height: 100%; /* Adjust as needed */
    width: 100%;
    padding: 20px;
    overflow-y: auto; /* Makes the content scrollable */
}
</style>